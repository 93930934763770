import React from 'react';
import { client } from 'cccisd-apollo';
import { SecureMessagingSingleConversation } from 'cccisd-laravel-secure-messaging';
import query from './learner.graphql';

const Fortress = window.cccisd.fortress;

class SecureMessagingLearner extends React.Component {
    state = {
        anotherParticipant: [],
        loading: true,
    };

    componentDidMount() {
        this.fetchInstructors();
    }

    fetchInstructors = async () => {
        const response = await client.query({
            query,
            variables: {},
            fetchPolicy: 'network-only',
        });

        const anotherParticipant =
            response.data.roles.instructorList.map(instructor => ({
                id: instructor.pawn.pawnId,
                name: instructor.user.fullName,
            }))[0] || undefined;
        this.setState({ anotherParticipant, loading: false });
    };

    render() {
        const { anotherParticipant, loading } = this.state;

        if (loading) {
            return null;
        }

        const user = Fortress.user;
        const primaryParticipant = { id: user.pawnId, name: `${user.firstName} ${user.lastName}` };

        return (
            <SecureMessagingSingleConversation
                primaryParticipant={primaryParticipant}
                anotherParticipant={anotherParticipant}
            />
        );
    }
}

export default SecureMessagingLearner;
