import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import logo from '../../../../../public/images/3C_Logo.png';

const NavBar = ({ className }) => {
    return (
        <Header
            className={className}
            logo={
                <div style={{ fontSize: '1.5em' }}>
                    <img
                        src={logo}
                        alt="3C Platform"
                        width="22"
                        height="33"
                        style={{
                            display: 'inline-block',
                            height: '100%',
                            marginBottom: '3px',
                            verticalAlign: 'middle',
                        }}
                    />{' '}
                    3C Platform
                </div>
            }
        />
    );
};

NavBar.defaultProps = {
    className: 'container',
};

NavBar.propTypes = {
    className: PropTypes.string,
};

export default NavBar;
