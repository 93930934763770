import React from 'react';
import { client } from 'cccisd-apollo';
import { SecureMessagingSupervision } from 'cccisd-laravel-secure-messaging';
import query from './site-admin.graphql';

class SecureMessagingSiteAdmin extends React.Component {
    state = {
        participantIds: [],
        loading: true,
    };

    componentDidMount() {
        this.fetchInstructors();
    }

    fetchInstructors = async () => {
        const response = await client.query({
            query,
            variables: {},
            fetchPolicy: 'network-only',
        });

        const participantIds = response.data.roles.instructorList.map(instructor => instructor.pawn.pawnId);

        this.setState({ participantIds, loading: false });
    };

    render() {
        const { participantIds, loading } = this.state;

        if (loading) {
            return null;
        }

        return <SecureMessagingSupervision participantIds={participantIds} />;
    }
}

export default SecureMessagingSiteAdmin;
