import React from 'react';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';

const Fortress = window.cccisd.fortress;

const PrintView = () => {
    return (
        <DeploymentPlayer
            pawnId={Fortress.user.acting.id}
            pawnHash={Fortress.user.acting.random_hash}
            deploymentHandle="printView"
            enablePrintMode
            flowProps={{ isPrintMode: true }}
            disableLayout
        />
    );
};

export default PrintView;
