import React from 'react';
import { client } from 'cccisd-apollo';
import { SecureMessagingMultipleConversations } from 'cccisd-laravel-secure-messaging';
import query from './instructor.graphql';

const Fortress = window.cccisd.fortress;

class SecureMessagingInstructor extends React.Component {
    state = {
        anotherParticipants: [],
        loading: true,
    };

    componentDidMount() {
        this.fetchLearners();
    }

    fetchLearners = async () => {
        const response = await client.query({
            query,
            variables: {},
            fetchPolicy: 'network-only',
        });

        const anotherParticipants = response.data.roles.learnerList.map(learner => ({
            id: learner.pawn.pawnId,
            name: learner.user.fullName,
        }));
        this.setState({ anotherParticipants, loading: false });
    };

    render() {
        const { anotherParticipants, loading } = this.state;

        if (loading) {
            return null;
        }

        const user = Fortress.user;
        const primaryParticipant = { id: user.pawnId, name: `${user.firstName} ${user.lastName}` };

        return (
            <SecureMessagingMultipleConversations
                primaryParticipant={primaryParticipant}
                anotherParticipants={anotherParticipants}
            />
        );
    }
}

export default SecureMessagingInstructor;
